import React, { useState, useEffect } from "react";
import {
  FormControl,
  Flex
} from '@chakra-ui/react'
import { useDispatch, useSelector } from "react-redux";
import customToast from 'utils/toastUtils';
import { Card } from "@chakra-ui/react";
import { MdMinimize } from "react-icons/md";
import SelectField from "components/fields/SelectField";
import FileUpload from "components/fields/FileUpload";
import DateRangePicker from "components/fields/DateRangePicker";
import InputField from "components/fields/InputField";
import { dateDiffInDays } from "utils/dateUtils";
import { addNewLeaveRequest, editLeaveRequest, getEmployeeAndReportingLine } from "../api/manageLeaves";
import { closeForm, setHolidaysList } from "../reducers/manageLeavesReducers";
import TextField from "components/fields/TextField";
import SwitchField from "components/fields/SwitchField";
import DatePicker from "components/fields/DatePicker";
import { fetchHolidaysList } from "views/admin/addHolidays/api/manageAddHoliday";
import { isFromDateGreaterThanTo } from "utils/dateUtils";
import { sendEmailNotification } from "views/user/myLeaves/api/notification";
import CheckField from "components/fields/CheckField";

const toast = customToast();

const LeaveRequestEditForm = (props) => {
  const { data, mode } = props;
  const [loading, setLoading] = useState(false);
  const {
    search, page, holidays, employees, sortBy, leaveTypes
  } = useSelector((state) => state.manageLeaves);
  const { session, employee } = useSelector((state) => state.auth);
  const [confirmNotify, setConfirmNotify] = useState(false);
  const dispatch = useDispatch();
  const isMedicalLeave = (id) => {
    return leaveTypes.filter(leave => leave.leave_type_id === Number(id))[0]?.leave_type_name === "medical"
  }
  const isAnnualLeave = (id) => {
    return leaveTypes.filter(leave => leave.leave_type_id === Number(id))[0]?.leave_type_name === "annual"
  }
  const isMaternityLeave = (id) => {
    return leaveTypes.filter(leave => leave.leave_type_id === Number(id))[0]?.leave_type_name === "maternity"
  }
  const initialFormState = {
    employee_id: data?.employee_id ?? '',
    is_half_day: data?.is_half_day ?? false,
    leave_request_id: data?.leave_request_id ?? '',
    leave_type_id: data?.leave_type_id ?? '',
    start_date: data?.start_date ? new Date(data?.start_date) : null,
    end_date: data?.end_date ? new Date(data?.end_date) : null,
    leave_reason: data?.leave_reason ?? '',
    files: data?.files ? data.files.map((file) => ({ name: file })) : [],
    half_leave_type: data?.half_leave_type ?? null
  };
  const [formState, setFormState] = useState(initialFormState);

  const handleInputChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(() => {
    setFormState({
      employee_id: data?.employee_id ?? '',
      is_half_day: data?.is_half_day ?? false,
      leave_request_id: data?.leave_request_id ?? '',
      leave_type_id: data?.leave_type_id ?? '',
      start_date: data?.start_date ? new Date(data?.start_date) : null,
      end_date: data?.end_date ? new Date(data?.end_date) : null,
      leave_reason: data?.leave_reason ?? '',
      files: data?.files ? data.files.map((file) => ({ name: file })) : [],
      half_leave_type: data?.half_leave_type ?? null
    });
  }, [data]);

  const getDuration = formState.leave_type_id && isMaternityLeave(formState.leave_type_id) ?
    dateDiffInDays(formState.start_date, formState.end_date, [], true) :
    dateDiffInDays(formState.start_date, formState.end_date, holidays)

  useEffect(() => {
    fetchHolidaysList().then(list => dispatch(setHolidaysList(list)))
  }, [dispatch])

  const handleSubmit = async (e) => {
    const formData = formState;
    e.preventDefault();
    if (formData.employee_id === "" || !formState.employee_id) {
      toast.showToast({
        title: 'Invalid Data',
        description: 'Employee id not found',
        status: 'error',
      })
      return;
    }
    if (formData.leave_type_id === "") {
      toast.showToast({
        title: 'Invalid Data',
        description: 'Please enter a valid leave type',
        status: 'error',
      })
      return;
    }
    if (!formData.start_date || formData.start_date === "" || isFromDateGreaterThanTo(formData.start_date, formData.end_date)) {
      toast.showToast({
        title: 'Invalid Data',
        description: 'Please enter a valid date range',
        status: 'error',
      })
      return;
    }
    if (formData.leave_type_id && isMedicalLeave(formData.leave_type_id) && formData.files.length === 0) {
      toast.showToast({
        title: 'Invalid Data',
        description: 'Please upload at least one file for medical leave',
        status: 'error',
      })
      return;
    }

    if (mode === "Edit" && formData?.leave_request_id) {
      setLoading(true)
      const filesData = isMedicalLeave(formData.leave_type_id) ? formData.files : []
      const prevFiles = data?.files ?? []
      dispatch(editLeaveRequest(formData, filesData, prevFiles, session?.user?.id, search, page, employee, sortBy)).then(() => setLoading(false))
    }
    else {
      delete formData.leave_request_id;
      setLoading(true)
      const filesData = isMedicalLeave(formData.leave_type_id) ? formData.files : [];
      const { employeeInfo, reportingLine } = await getEmployeeAndReportingLine(formState.employee_id);
      console.log({ employeeInfo, reportingLine })
      dispatch(addNewLeaveRequest(formData, employee, filesData, session?.user?.id, search, page, sortBy)).then(
        (isAdded) => {
          setLoading(false);
          if (isAdded && confirmNotify && employeeInfo && reportingLine?.length > 0) sendEmailNotification(
            employeeInfo, formData, leaveTypes, holidays, reportingLine, ''
          ).then(() => {
            setLoading(false);
            // resetFormState();
          });
        }
      )
    }
  };
  return (
    <div className="my-6 ml-4">
      <Card className="bg-white dark:!bg-navy-800 px-4 pb-2 dark:shadow-none">
        <div className="flex mb-3 mx-[1rem] h-full items-center justify-between">
          <div className="text-xl mt-8 mb-3 ml-1 font-bold text-navy-700 dark:text-white">
            {`${mode} Leave Request`}
          </div>
          <button
            onClick={() => dispatch(closeForm())}
            className="text-xl text-brand-500 hover:text-brand-600 active:text-brand-700 dark:text-brand-700"
          >
            <MdMinimize size={35} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="mx-4">
          <Flex justify="start" align="center" mb={1}>
            {<FormControl isRequired className="flex flex-col mx-1 mb-3 max-w-[49%]">
              <SelectField
                id='employee_id'
                label='Employee'
                // capitalize={false}
                placeholder="Select Employee"
                value={formState.employee_id}
                onChange={(e) => handleInputChange("employee_id", e.target.value)}
                options={employees.map((emp) => ({ id: emp.employee_id, value: emp.employee_name }))}
              />
            </FormControl>}
            <FormControl isRequired className="flex flex-col mx-1 mb-3 max-w-[49%]">
              <SelectField
                id='leave_type_id'
                label='Leave Type'
                placeholder="Select Leave Type"
                value={formState.leave_type_id}
                onChange={(e) => handleInputChange("leave_type_id", e.target.value)}
                options={leaveTypes.map((leave) => ({ id: leave.leave_type_id, value: leave.leave_type_label }))}
              />
            </FormControl>
            {formState.leave_type_id && isAnnualLeave(formState.leave_type_id) && <FormControl className="flex flex-col mx-1 mb-3">
              <Flex justify={formState.is_half_day ? "space-evenly" : "start"} align="center" mb={1}>
                <SwitchField
                  id='is_half_day'
                  label='Half Day Leave'
                  className="w-[30%]"
                  value={formState.is_half_day}
                  onChange={(e) => handleInputChange("is_half_day", e.target.checked)}
                />
                {formState.is_half_day && (
                  <SelectField
                    extra="ml-4 w-[40%]"
                    id='half_leave_type'
                    label='Half Leave Duration'
                    placeholder="Select Duration"
                    value={formState.half_leave_type}
                    onChange={(e) => handleInputChange("half_leave_type", e.target.value)}
                    options={[{ value: "First Half", id: "first-half" }, { value: "Second Half", id: "second-half" }]}
                  />
                )}
              </Flex>
            </FormControl>}
          </Flex>
          <Flex justify="space-between" align="center" mb={1}>
            <FormControl isRequired className="flex flex-col mx-1 mb-3">
              {formState.is_half_day ?
                <DatePicker
                  placeholder="Select leave date"
                  id="start_date"
                  label="Leave Date"
                  startDate={formState.start_date}
                  limitPastTo30Days={false}
                  setStartDate={(e) => handleInputChange('start_date', e)}
                  excludeDates={formState.leave_type_id && isMaternityLeave(formState.leave_type_id) ? [] : holidays.map(dt => new Date(dt))}
                  includeWeekends={formState.leave_type_id && isMaternityLeave(formState.leave_type_id) ? true : false}
                /> :
                <DateRangePicker
                  startDate={formState.start_date}
                  setStartDate={(val) => handleInputChange('start_date', val)}
                  endDate={formState.end_date}
                  setEndDate={(val) => handleInputChange('end_date', val)}
                  placeholder="Select date range"
                  id="start_date"
                  label="Leave Date (Range)"
                  limitPastTo30Days={false}
                  includeWeekends={formState.leave_type_id && isMaternityLeave(formState.leave_type_id) ? true : false}
                  excludeDates={formState.leave_type_id && isMaternityLeave(formState.leave_type_id) ? [] : holidays.map(dt => new Date(dt))}
                />}
            </FormControl>
            <FormControl className="flex flex-col mx-1 mb-3">
              <InputField
                label='Leave Duration'
                id="start_date"
                value={formState.is_half_day ? '0.5 day' : `${getDuration} ${getDuration > 1 ? 'days' : 'day'}`}
                type="text"
                readonly
                disabled
              />
            </FormControl>
          </Flex>
          <FormControl className="flex flex-col mx-1 mb-6">
            <TextField
              label='Leave Reason'
              id='leave_reason'
              placeholder='Enter Leave Reason'
              rows={3}
              value={formState.leave_reason}
              onChange={(e) => handleInputChange('leave_reason', e.target.value)}
            />
          </FormControl>
          {formState.leave_type_id && isMedicalLeave(formState.leave_type_id) && <FormControl isRequired className="flex flex-col mx-1 mb-6">
            <FileUpload
              label='Attach file(s)'
              id='attachment_path'
              files={formState.files}
              setFiles={(value) => handleInputChange('files', value)}
              placeholderText={`Please attach at least one medical attachment.`}
              subLabel="This leave type necessitates a doctor's note/medical certificate. In the absence of such documentation, kindly mark it as annual/casual leave."
            />
          </FormControl>}
          <FormControl className="flex flex-col mx-1 mb-3">
            <CheckField
              className='ml-2 flex items-center'
              id='confirm_check'
              label="Notify the reporting line of employee via email."
              value={confirmNotify}
              onChange={(e) => setConfirmNotify(e.target.checked)}
            />
          </FormControl>
          <div className="flex justify-end align-center mb-3">
            <button
              disabled={loading}
              type='submit'
              className="linear flex items-center justify-center mt-6 mb-3 w-40 rounded-xl bg-brand-500 py-[7px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:bg-gray-600"
            >
              Save
            </button>
          </div>
        </form>
      </Card>
    </div>
  )
}

export default LeaveRequestEditForm;