import { setIsBankDetailsLoading, setBankDetails } from '../reducers/manageBankReducers';
import customToast from 'utils/toastUtils';
import { supabase } from 'utils/supabase';

const toast = customToast();

export const addBankDetails = (employeeId, bankDetails) => async () => {
  try {
    const { error } = await supabase
      .from('bank_details')
      .insert(bankDetails.map(bank => ({
        employee_id: employeeId,
        bank_title: bank.bank_title,
        bank_name: bank.bank_name,
        account_number: bank.account_number,
        iban_number: bank.iban_number,
        is_active: bank.employee_status
      })))
      .select();

    if (error) {
      throw new Error(`Error adding bank details. ${error.message}`);
    }

    toast.showToast({
      title: 'Success',
      description: 'Bank details added successfully.',
      status: 'success',
    });
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to add bank details.',
      status: 'error',
    });
  }
};

export const editBankDetails = (employeeId, bankDetails) => async () => {
  try {
    await Promise.all(
      bankDetails.map(async (bank) => {
        if (bank.bank_details_id) {
            await supabase
            .from('bank_details')
            .update({
              bank_title: bank.bank_title,
              bank_name: bank.bank_name,
              account_number: bank.account_number,
              iban_number: bank.iban_number,
              is_active: bank.is_active,
            })
            .eq('bank_details_id', bank.bank_details_id);
        } else {
             await supabase
            .from('bank_details')
            .insert([{
              employee_id: employeeId,
              bank_title: bank.bank_title,
              bank_name: bank.bank_name,
              account_number: bank.account_number,
              iban_number: bank.iban_number,
              is_active: bank.is_active,
            }]);
        }
      })
    );
    toast.showToast({
      title: 'Success',
      description: 'Bank details updated successfully.',
      status: 'success',
    });
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: `Unable to update bank details. ${error.message}`,
      status: 'error',
    });
  }
};



export const fetchBankDetailsByEmployeeId = (employeeId) => async (dispatch) => {
  try {
    dispatch(setIsBankDetailsLoading(true));

    const { data, error } = await supabase
      .from('bank_details')
      .select('bank_details_id, bank_title, bank_name, account_number, iban_number, is_active')
      .eq('employee_id', employeeId);

    if (error) {
      throw new Error(`Error fetching bank details. ${error.message}`);
    }

    if (data && data.length > 0) {
      dispatch(setBankDetails(data));
    }

    dispatch(setIsBankDetailsLoading(false));
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to fetch bank details.',
      status: 'error',
    });
    dispatch(setIsBankDetailsLoading(false));
  }
};
