import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  medicalHistory: null, // Default value for medical history
  familyMembers: null, // Default value for family members
  isMedicalHistoryLoading: false,
};

const manageMedicalReducers = createSlice({
  name: 'manageMedical',
  initialState,
  reducers: {
    setMedicalHistory: (state, action) => {
      state.medicalHistory = action.payload;
    },
    setIsMedicalHistoryLoading: (state, action) => {
      state.isMedicalHistoryLoading = action.payload;
    },
    setFamilyMembers: (state, action) => {
      state.familyMembers = action.payload;
    },
  },
});

export const { setMedicalHistory, setIsMedicalHistoryLoading, setFamilyMembers } = manageMedicalReducers.actions;

export default manageMedicalReducers.reducer;
