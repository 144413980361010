import { setIsLoading} from '../reducers/manageEmployeesReducers';
import customToast from 'utils/toastUtils';
import { supabase } from 'utils/supabase';
import { EMPLOYMENT_HISTORY_STORAGE } from 'variables/common';


const toast = customToast();

export const addEmploymentHistory = (employmentHistoryData) => async (dispatch) => {
  dispatch(setIsLoading(true));
  const { companyHistories, total_years_experience, employee_id } = employmentHistoryData;

  try {
    const insertPromises = companyHistories.map(async (companyHistory) => {
      const { company_name, designation, emp_start_date, emp_end_date, experience_letter } = companyHistory;

      let experienceLetterPath = companyHistory.experience_letter;
      if (experience_letter && experience_letter instanceof File) {
        experienceLetterPath = await uploadAttachment(experience_letter, employee_id);
      }

      const { data, error } = await supabase
        .from('employment_history')
        .insert({
          employee_id,
          total_years_experience,
          company_name,
          designation,
          emp_start_date,
          emp_end_date,
          experience_letter: experienceLetterPath,
        });

      if (error) {
        throw new Error(`Failed to insert company ${company_name}: ${error.message}`);
      }
      return data;
    });

    await Promise.all(insertPromises);

    toast.showToast({
      title: 'Success',
      description: 'Employment history added successfully.',
      status: 'success',
    });
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: error.message || 'Failed to add employment history',
      status: 'error',
    });
    console.error('Error adding employment history:', error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const editEmploymentHistory = (employeeId, employmentHistoryData) => async (dispatch) => {
  dispatch(setIsLoading(true));

  const { companyHistories, total_years_experience } = employmentHistoryData;

  try {
    const promises = companyHistories.map(async (companyHistory) => {
      const { company_name, designation, emp_start_date, emp_end_date, experience_letter } = companyHistory;

      let experienceLetterPath = companyHistory.experience_letter;
      if (experience_letter && experience_letter instanceof File) {
        experienceLetterPath = await uploadAttachment(experience_letter, employeeId);
      }
      const { data: existingRecord, error: fetchError } = await supabase
        .from('employment_history')
        .select('*')
        .eq('employee_id', employeeId)
        .eq('company_name', company_name)
        .single();

      if (fetchError && fetchError.code !== 'PGRST116') {
        throw new Error(`Failed to fetch existing record for ${company_name}: ${fetchError.message}`);
      }

      if (existingRecord) {
        const { error: updateError } = await supabase
          .from('employment_history')
          .update({
            total_years_experience,
            designation,
            emp_start_date, 
            emp_end_date,
            experience_letter: experienceLetterPath,
          })
          .eq('employee_id', employeeId)
          .eq('company_name', company_name);

        if (updateError) {
          throw new Error(`Failed to update company ${company_name}: ${updateError.message}`);
        }
      } else {
        const { error: insertError } = await supabase
          .from('employment_history')
          .insert({
            employee_id: employeeId,
            total_years_experience,
            company_name,
            designation,
            emp_start_date, 
            emp_end_date,
            experience_letter: experienceLetterPath,
          });

        if (insertError) {
          throw new Error(`Failed to insert company ${company_name}: ${insertError.message}`);
        }
      }
    });
    await Promise.all(promises);

    toast.showToast({
      title: 'Success',
      description: 'Employment history updated successfully.',
      status: 'success',
    });
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: error.message || 'Failed to update employment history',
      status: 'error',
    });
    console.error('Error updating employment history:', error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const fetchEmploymentHistoryByEmployeeId = (employeeId) => async (dispatch) => {
  dispatch(setIsLoading(true));

  try {
    const { data, error } = await supabase
      .from('employment_history')
      .select('*')
      .eq('employee_id', employeeId);

    if (error) {
      throw new Error(error.message);
    }

    const employmentHistory = {
      total_years_experience: data[0]?.total_years_experience || '',
      companyHistories: data.map((entry) => ({
        company_name: entry.company_name || '',
        designation: entry.designation || '',
        emp_start_date: entry.emp_start_date || '', 
        emp_end_date: entry.emp_end_date || '',
        experience_letter: entry.experience_letter || null,
      })),
    };

    return employmentHistory;
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to fetch employment history.',
      status: 'error',
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const uploadAttachment = async (file, userId) => {
  const filename = `${new Date().getTime()}_${file.name}`;
  const { data, error } = await supabase.storage.from(EMPLOYMENT_HISTORY_STORAGE).upload(`assets/${userId}/${filename}`, file);

  if (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to upload file.',
      status: 'error',
    });
    return null;
  }
  return data.path;
};

export const deleteAttachmentsFromStorage = async (files) => {
  const updated = files.map((file) =>
    file?.startsWith(`${EMPLOYMENT_HISTORY_STORAGE}/`) ? file.replace(`${EMPLOYMENT_HISTORY_STORAGE}/`, '') : file
  );
  const { error } = await supabase.storage.from(EMPLOYMENT_HISTORY_STORAGE).remove(updated);

  if (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to remove file from storage.',
      status: 'error',
    });
  }
};


