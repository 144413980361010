import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import customToast from './toastUtils';

const toast = customToast();
const useGoogleCalendar = () => {
  const [gapiLoaded, setGapiLoaded] = useState(false);
  const [gisLoaded, setGisLoaded] = useState(false);
  const [gapiClient, setGapiClient] = useState(null);
  const [tokenClient, setTokenClient] = useState(null);

  const accessToken = useSelector((state) => state.auth.session.provider_token);

  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const DISCOVERY_DOC = process.env.REACT_APP_GOOGLE_DISCOVERY_DOC;
  const SCOPES = process.env.REACT_APP_GOOGLE_SCOPES;

  useEffect(() => {
    const loadGapi = () => {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';
      script.onload = () => {
        window.gapi.load('client', async () => {
          await window.gapi.client.init({
            apiKey: API_KEY,
            discoveryDocs: [DISCOVERY_DOC],
          });
          setGapiClient(window.gapi);
          setGapiLoaded(true);
        });
      };
      document.body.appendChild(script);
    };

    const loadGis = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.onload = () => {
        const tokenClient = window.google.accounts.oauth2.initTokenClient({
          client_id: CLIENT_ID,
          scope: SCOPES,
          callback: (response) => {
            console.log('Access token received:', response);
          },
        });
        setTokenClient(tokenClient);
        setGisLoaded(true);
      };
      document.body.appendChild(script);
    };

    loadGapi();
    loadGis();
    // eslint-disable-next-line
  }, []);

  const connectGoogleCalendar =  () => {
    if (!gapiLoaded || !gisLoaded) {
        // throw new Error('Google APIs not loaded');
        toast.showToast({
          title: 'Error',
          description: 'Google Calendar integration failed',
          status: 'warning',
        })
      }
      if (accessToken) {
        gapiClient.client.setToken({ access_token: accessToken });
      } else if (gapiClient.client.getToken() === null) {
        tokenClient.requestAccessToken({ scope: SCOPES });
      }
  }

  const createOutOfOfficeEvent = async (startDateTime, endDateTime) => {
    try {
      const event = {
        summary: 'Out of Office',
        start: {
          dateTime: startDateTime,
          timeZone: 'America/Los_Angeles',
        },
        end: {
          dateTime: endDateTime,
          timeZone: 'America/Los_Angeles',
        },
        eventType: 'outOfOffice',
        transparency: 'opaque',
        // outOfOfficeProperties: {
        //   autoDeclineMode: 'declineAllConflictingInvitations',
        //   declineMessage: 'Employee is on leave',
        // },
      };

      const response = await gapiClient.client.calendar.events.insert({
        calendarId: 'primary',
        resource: event,
      });

      toast.showToast({
        title: 'Info',
        description: "Added event to your calendar",
        status: 'info',
      });
      console.log('Out of Office event created:', response.result.htmlLink);
    } catch (err) {
      console.error('Error creating Out of Office event:', err);
    }
  };

  return { createOutOfOfficeEvent, connectGoogleCalendar, gapiLoaded, gisLoaded };
};

export default useGoogleCalendar;
