import React, { useState, useEffect } from "react";
import {
  FormControl,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  FormLabel
} from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import customToast from 'utils/toastUtils';
import { Card } from "@chakra-ui/react";
import { MdMinimize } from "react-icons/md";
import TextField from "components/fields/TextField";
import SelectField from "components/fields/SelectField";
import FileUpload from "components/fields/FileUpload";
import DateRangePicker from "components/fields/DateRangePicker";
import InputField from "components/fields/InputField";
import { dateDiffInDays } from "utils/dateUtils";
import { addLeaveEventToCalendar, addNewLeaveRequest, calculateRemainingLeaves, editLeaveRequest, getEmployeeReportingLine } from "../api/myLeaves";
import { closeForm, setEmployees, setHolidaysList, setNotifyEmployees, setNotifyMessage, setReportingLineInfo, } from "../reducers/myLeavesReducers";
import SwitchField from "components/fields/SwitchField";
import DatePicker from "components/fields/DatePicker";
import { fetchHolidaysList } from "views/admin/addHolidays/api/manageAddHoliday";
import { sendEmailNotification } from "../api/notification";
import { isFromDateGreaterThanTo } from "utils/dateUtils";
import MultiSelect from "components/fields/MultiSelect";
import { fetchAllEmployees } from "views/user/manageLeaves/api/manageLeaves";
import CheckField from "components/fields/CheckField";
import useGoogleCalendar from "utils/googleCalendar";

const toast = customToast();

const LeaveRequestForm = (props) => {
  const { mode, data } = props;
  const [loading, setLoading] = useState(false);
  const [confirmCheck, setConfirmCheck] = useState(false);
  const [addToCalendar, setAddToCalendar] = useState(false);
  const {
    page, leaveTypes, searchDate, holidays, employeeLeaveQuota, sortBy, employees, notifyemployee,
    notification_message, notifymessage, reportingLine
  } = useSelector((state) => state.myLeaves);
  const { session, employee } = useSelector((state) => state.auth)
  const dispatch = useDispatch();
  const { createOutOfOfficeEvent, gapiLoaded, gisLoaded, connectGoogleCalendar } = useGoogleCalendar();
  const isMedicalLeave = (id) => {
    return leaveTypes.filter(leave => leave.leave_type_id === Number(id))[0]?.leave_type_name === "medical"
  }
  const isAnnualLeave = (id) => {
    return leaveTypes.filter(leave => leave.leave_type_id === Number(id))[0]?.leave_type_name === "annual"
  }
  const isMaternityLeave = (id) => {
    return leaveTypes.filter(leave => leave.leave_type_id === Number(id))[0]?.leave_type_name === "maternity"
  }
  const initialFormState = {
    employee_id: employee?.employee_id,
    is_half_day: data?.is_half_day ?? false,
    leave_request_id: data?.leave_request_id ?? '',
    leave_type_id: data?.leave_type_id ?? '',
    start_date: data?.start_date ? new Date(data?.start_date) : null,
    end_date: data?.end_date ? new Date(data?.end_date) : null,
    leave_reason: data?.leave_reason ?? '',
    files: data?.files ? data.files.map((file) => ({ name: file })) : [],
    half_leave_type: data?.half_leave_type ?? null
  };
  const [formState, setFormState] = useState(initialFormState);

  const handleInputChange = (field, value) => {
    if (field === 'notification_member_ids' || field === 'notification_message') {
      dispatch(field === 'notification_member_ids' ? setNotifyEmployees(value) : setNotifyMessage(value));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };

  const getDuration = formState.leave_type_id && isMaternityLeave(formState.leave_type_id) ?
    dateDiffInDays(formState.start_date, formState.end_date, [], true) :
    dateDiffInDays(formState.start_date, formState.end_date, holidays)

  useEffect(() => {
    setFormState({
      employee_id: employee?.employee_id,
      is_half_day: data?.is_half_day ?? false,
      leave_request_id: data?.leave_request_id ?? '',
      leave_type_id: data?.leave_type_id ?? '',
      start_date: data?.start_date ? new Date(data?.start_date) : null,
      end_date: data?.end_date ? new Date(data?.end_date) : null,
      leave_reason: data?.leave_reason ?? '',
      files: data?.files ? data.files.map((file) => ({ name: file })) : [],
      half_leave_type: data?.half_leave_type ?? null
    });
  }, [data, employee?.employee_id]);

  useEffect(() => {
    fetchHolidaysList().then(list => dispatch(setHolidaysList(list)))
  }, [dispatch])

  useEffect(() => {
    fetchAllEmployees().then(({ data }) => { if (data) dispatch(setEmployees(data)) })
  }, [dispatch])

  useEffect(() => {
    getEmployeeReportingLine(employee).then((data) => {
      if (data) dispatch(setReportingLineInfo(data.map(emp => emp.employee_id)));
    })
  }, [dispatch, employee])

  useEffect(() => {
    if (employeeLeaveQuota && formState.leave_type_id && formState.start_date) {
      const leaveType = leaveTypes.filter(leave => leave.leave_type_id === Number(formState.leave_type_id))[0]?.leave_type_name
      const prevLeave = data?.leave_type_id ? leaveTypes.filter(leave => leave.leave_type_id === Number(data?.leave_type_id))[0]?.leave_type_name : undefined
      if (leaveType) {
        const leaveDuration = formState.leave_type_id && isMaternityLeave(formState.leave_type_id) ?
          dateDiffInDays(formState.start_date, formState.end_date, [], true) :
          dateDiffInDays(formState.start_date, formState.end_date, holidays);
        let remaining_leaves = calculateRemainingLeaves(employeeLeaveQuota, leaveType)
        if (mode === "Edit" && prevLeave && prevLeave === leaveType) {
          remaining_leaves = remaining_leaves + data?.leave_duration
        }
        if ((remaining_leaves <= 0) || (remaining_leaves < leaveDuration)) {
          toast.showToast({
            title: 'Quota Exceeded',
            description: 'Your quota has exceeded for this leave type',
            status: 'warning',
          })
        }
      }
    }
    // eslint-disable-next-line
  }, [employeeLeaveQuota, formState.leave_type_id, formState.start_date, formState.end_date])

  const resetFormState = () => {
    setFormState(initialFormState);
    dispatch(setNotifyEmployees([]));
    dispatch(setNotifyMessage(''));
  };

  const handleSubmit = (e) => {
    const { notification_member_ids, notification_message, ...formData } = formState;
    e.preventDefault();
    if (formData.leave_type_id === "") {
      toast.showToast({
        title: 'Invalid Data',
        description: 'Please enter a valid leave type',
        status: 'error',
      })
      return;
    }
    if (!formData.start_date || formData.start_date === "" || isFromDateGreaterThanTo(formData.start_date, formData.end_date)) {
      toast.showToast({
        title: 'Invalid Data',
        description: 'Please enter a valid date range',
        status: 'error',
      })
      return;
    }
    if (isAnnualLeave(formData.leave_type_id) && getDuration > 1 && !formState.is_half_day && !confirmCheck) {
      toast.showToast({
        title: 'Please confirm',
        description: 'You need to confirm the checkbox selection to proceed',
        status: 'error',
      })
      return;
    }
    if (formData.leave_type_id && isMedicalLeave(formData.leave_type_id) && formData.files?.length === 0) {
      toast.showToast({
        title: 'Invalid Data',
        description: 'Please upload at least one file for medical leave',
        status: 'error',
      })
      return;
    }

    if (mode === "Edit" && formData?.leave_request_id) {
      setLoading(true)
      const filesData = isMedicalLeave(formData.leave_type_id) ? formData.files : []
      const prevFiles = data?.files ?? []
      dispatch(editLeaveRequest(formData, filesData, prevFiles, session?.user?.id, searchDate, page, sortBy)).then(() => setLoading(false))
      resetFormState();
    }
    else {
      delete formData.leave_request_id;
      setLoading(true)
      const filesData = isMedicalLeave(formData.leave_type_id) ? formData.files : []
      dispatch(addNewLeaveRequest(formData, filesData, session?.user?.id, searchDate, page, sortBy)).then(
        (isAdded) => {
          setLoading(false);
          if (isAdded) {
            // send email to team
            sendEmailNotification(
              employee, formData, leaveTypes, holidays, notifyemployee?.length > 0 ? notifyemployee : reportingLine, notifymessage
            ).then(() => {
              setLoading(false);
              resetFormState();
            });
            // add event to calendar
            if (addToCalendar) {
              addLeaveEventToCalendar(
                formData.start_date, formData.end_date, formData.is_half_day, 
                formData.half_leave_type === "first-half", createOutOfOfficeEvent
              );
            }
          }
        }
      )
      // setFormState(initialFormState);
    }
  };
  return (
    <div className="my-6 ml-4">
      <Card className="bg-white dark:!bg-navy-800 px-4 pb-2 dark:shadow-none">
        <div className="flex mb-3 mx-[1rem] h-full items-center justify-between">
          <div className="text-xl mt-8 mb-3 ml-1 font-bold text-navy-700 dark:text-white">
            {mode === "Edit" ? "Edit Leave Request" : "New Leave Request"}
          </div>
          <button
            onClick={() => dispatch(closeForm())}
            className="text-xl text-brand-500 hover:text-brand-600 active:text-brand-700 dark:text-brand-700"
          >
            <MdMinimize size={35} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="mx-4">
          <Flex justify="start" align="center" mb={1}>
            <FormControl isRequired className="flex flex-col mx-1 mb-3 max-w-[49%]">
              <SelectField
                id='leave_type_id'
                label='Leave Type'
                placeholder="Select Leave Type"
                value={formState.leave_type_id}
                onChange={(e) => handleInputChange("leave_type_id", e.target.value)}
                options={leaveTypes.map((leave) => ({ id: leave.leave_type_id, value: leave.leave_type_label }))}
              />
            </FormControl>
            {formState.leave_type_id && isAnnualLeave(formState.leave_type_id) && <FormControl className="flex flex-col mx-1 mb-3">
              <Flex justify={formState.is_half_day ? "space-evenly" : "start"} align="center" mb={1}>
                <SwitchField
                  id='is_half_day'
                  label='Half Day Leave'
                  className="w-[30%]"
                  value={formState.is_half_day}
                  onChange={(e) => handleInputChange("is_half_day", e.target.checked)}
                />
                {formState.is_half_day && (
                  <SelectField
                    extra="ml-4 w-[40%]"
                    id='half_leave_type'
                    label='Half Leave Duration'
                    placeholder="Select Duration"
                    value={formState.half_leave_type}
                    onChange={(e) => handleInputChange("half_leave_type", e.target.value)}
                    options={[{ value: "First Half", id: "first-half" }, { value: "Second Half", id: "second-half" }]}
                  />
                )}
              </Flex>
            </FormControl>}
          </Flex>
          <Flex justify="space-between" align="center" mb={1}>
            <FormControl isRequired className="flex flex-col mx-1 mb-3">
              {formState.is_half_day ?
                <DatePicker
                  placeholder="Select leave date"
                  id="start_date"
                  label="Leave Date"
                  startDate={formState.start_date}
                  limitPastTo30Days={true}
                  setStartDate={(e) => handleInputChange('start_date', e)}
                  includeWeekends={formState.leave_type_id && isMaternityLeave(formState.leave_type_id) ? true : false}
                  excludeDates={formState.leave_type_id && isMaternityLeave(formState.leave_type_id) ? [] : holidays.map(dt => new Date(dt))}
                /> :
                <DateRangePicker
                  startDate={formState.start_date}
                  setStartDate={(val) => handleInputChange('start_date', val)}
                  endDate={formState.end_date}
                  setEndDate={(val) => handleInputChange('end_date', val)}
                  placeholder="Select date range"
                  id="start_date"
                  label="Leave Date (Range)"
                  limitPastTo30Days={true}
                  includeWeekends={formState.leave_type_id && isMaternityLeave(formState.leave_type_id) ? true : false}
                  excludeDates={formState.leave_type_id && isMaternityLeave(formState.leave_type_id) ? [] : holidays.map(dt => new Date(dt))}
                />}
            </FormControl>
            <FormControl className="flex flex-col mx-1 mb-3">
              <InputField
                label='Leave Duration'
                id="start_date"
                value={formState.is_half_day ? '0.5 day' : `${getDuration} ${getDuration > 1 ? 'days' : 'day'}`}
                type="text"
                readonly
                disabled
              />
            </FormControl>
          </Flex>
          {formState.leave_type_id && isAnnualLeave(formState.leave_type_id) && getDuration > 1 && !formState.is_half_day &&
            <FormControl className="flex flex-col mx-1 mb-3">
              <CheckField
                className='ml-2 flex items-center'
                id='confirm_check'
                label="I confirm that I have discussed my leaves and substitution plan with my team and manager."
                value={confirmCheck}
                onChange={(e) => setConfirmCheck(e.target.checked)}
              />
            </FormControl>}
          {mode === "Add" && gapiLoaded && gisLoaded &&
            <FormControl className="flex flex-col mx-1 mb-3">
              <CheckField
                className='ml-2 flex items-center'
                id='add_to_calendar_check'
                label="Add an 'Out of Office' event to my Google Calendar."
                value={addToCalendar}
                onChange={(e) => {
                  if (e.target.checked) connectGoogleCalendar()
                  setAddToCalendar(e.target.checked)
                }}
              />
            </FormControl>}
          <FormControl className="flex flex-col mx-1 mb-3">
            <TextField
              label='Leave Reason'
              id='leave_reason'
              placeholder='Enter Leave Reason'
              rows={3}
              value={formState.leave_reason}
              onChange={(e) => handleInputChange('leave_reason', e.target.value)}
            />
          </FormControl>
          {formState.leave_type_id && isMedicalLeave(formState.leave_type_id) && <FormControl isRequired className="flex flex-col mx-1 mb-3">
            <FileUpload
              label='Attach file(s)'
              id='attachment_path'
              files={formState.files}
              setFiles={(value) => handleInputChange('files', value)}
              placeholderText={`Please attach at least one medical attachment.`}
              subLabel="This leave type necessitates a doctor's note/medical certificate. In the absence of such documentation, kindly mark it as annual/casual leave."
            />
          </FormControl>}
          {mode === "Add" && (<Accordion allowToggle>
            <AccordionItem borderWidth={0} className="!border-b-0">
              <h2>
                <AccordionButton paddingX={1} pb={1}>
                  <Box as='span' flex='1' alignItems='center' textAlign='left'>
                    <FormLabel
                      className="!text-sm font-bold text-navy-700 dark:text-white"
                    >
                      Notify Team (via Email)
                    </FormLabel>
                  </Box>

                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={2} pt={0} paddingX={1}>
                <Flex justify="space-between" align="center" mb={1}>
                  <FormControl className="flex flex-col mb-3">
                    <MultiSelect
                      options={employees.map((emp) => ({
                        id: emp.employee_id,
                        name: emp.employee_email,
                      }))}
                      formFieldName='notification_member_ids'
                      label='Team Members'
                      initialVal={notifyemployee}
                      onChange={(value) => dispatch(setNotifyEmployees(value))}
                      loggedInEmployeeEmail={employee.employee_email}
                      managersEmails={reportingLine}
                    />
                  </FormControl>
                </Flex>
                <FormControl className="flex flex-col mb-6">
                  <TextField
                    label='Additional Message'
                    id='notification_message_id'
                    placeholder='Enter Notification Message'
                    rows={3}
                    value={notification_message}
                    onChange={(e) => dispatch(setNotifyMessage(e.target.value))}
                  />
                </FormControl>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>)}

          <div className="flex justify-end align-center mb-3">
            <button
              disabled={loading || (formState.leave_type_id && isAnnualLeave(formState.leave_type_id) && getDuration > 1 && !formState.is_half_day && !confirmCheck)}
              type='submit'
              className="linear flex items-center justify-center mt-6 mb-3 w-40 rounded-xl bg-brand-500 py-[7px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:bg-gray-600"
            >
              Save
            </button>
          </div>
        </form>
      </Card>
    </div>
  )
}

export default LeaveRequestForm
