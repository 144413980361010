import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { supabase } from '../../utils/supabase'
import authImg from "assets/img/auth/auth-bg-new.png";
import { Image } from '@chakra-ui/react';
import Logo from 'assets/img/icons/logo-png.png'

const Login = () => {
  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                <div className="flex items-start justify-start ml-[-6rem]">
                  {/* <Image src={PG} boxSize='30px' /> */}
                  {/* <Link
                        to="/"
                      > */}
                  <Image src={Logo} height='120px' width='auto' />
                  {/* HR <span className="font-medium">APP</span>  */}
                  {/* <span className="font-small">PORTAL</span> */}
                  {/* </Link> */}
                </div>
                <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
                  {/* Sign in section */}
                  <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">

                    <div className="mb-6 text-navy-700 dark:text-white text-4xl font-bold">
                      Welcome to HR Portal
                    </div>

                    {/* <h4 className="mb-2.5 text-2xl font-bold text-navy-700 dark:text-white">
                      Sign In
                    </h4> */}
                    <div>
                      <Auth
                        supabaseClient={supabase}
                        appearance={{
                          theme: ThemeSupa,
                          // variables: {
                          //   default: {
                          //     colors: {
                          //       brand: "#422AFB",
                          //       brandAccent: "#3311DB",
                          //     },
                          //   },
                          // }
                        }}
                        onlyThirdPartyProviders
                        providers={['google']}
                        showLinks={false}
                        providerScopes={{
                          google: 'https://www.googleapis.com/auth/calendar.events',
                        }}
                        // theme="dark"
                      />
                    </div>
                  </div>
                </div>
                <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[59vw] 2xl:w-[55vw]">
                  <div
                    className="absolute flex h-full w-full items-end justify-center bg-cover bg-center"
                    style={{ backgroundImage: `url(${authImg})` }}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>

  )
}

export default Login