import { setIsFamilyDetailsLoading, setFamilyDetails, } from '../reducers/manageFamilyReducers'
import customToast from 'utils/toastUtils';
import { supabase } from 'utils/supabase';


const toast = customToast();

export const addFamilyDetails = (employeeId, familyDetails) => async () => {
  try {
    const familyDetailsArray = [
      { employee_id: employeeId, name: familyDetails.spouse_name, date_of_birth: familyDetails.spouse_dob, family_member_type: 'spouse' },
    ];

    familyDetails.children.forEach((child) => {
      familyDetailsArray.push({
        employee_id: employeeId,
        name: child.name,
        date_of_birth: child.dob,
        family_member_type: 'child',
      });
    });

    const { error } = await supabase
      .from('family_members')
      .insert(familyDetailsArray)
      .select();

    if (error) {
      toast.showToast({
        title: 'Error adding family details',
        description: error.message,
        status: 'error',
      });
    } else {
      toast.showToast({
        title: 'Success',
        description: 'Family details added successfully.',
        status: 'success',
      });
    }
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to add family details.',
      status: 'error',
    });
  }
};

export const editFamilyDetails = (employeeId, familyDetails) => async () => {
  try {
    const familyDetailsArray = [
      {
        employee_id: employeeId,
        name: familyDetails.spouse_name,
        date_of_birth: familyDetails.spouse_dob,
        family_member_type: 'spouse',
      },
    ];

    familyDetails.children.forEach((child) => {
      familyDetailsArray.push({
        employee_id: employeeId,
        name: child.name,
        date_of_birth: child.dob,
        family_member_type: 'child',
      });
    });

    const promises = familyDetailsArray.map(async (familyMember) => {
      const { data: existingMember, error: fetchError } = await supabase
        .from('family_members')
        .select('family_member_id')
        .eq('employee_id', employeeId)
        .eq('family_member_type', familyMember.family_member_type)
        .single();

      if (fetchError && fetchError.code !== 'PGRST116') {
        throw new Error(`Error fetching family member: ${fetchError.message}`);
      }

      if (existingMember) {
        const { error: updateError } = await supabase
          .from('family_members')
          .update({
            name: familyMember.name,
            date_of_birth: familyMember.date_of_birth,
          })
          .eq('employee_id', employeeId)
          .eq('family_member_type', familyMember.family_member_type);

        if (updateError) {
          throw new Error(`Error updating family member ${familyMember.name}: ${updateError.message}`);
        }
      } else {
        const { error: insertError } = await supabase
          .from('family_members')
          .insert({
            employee_id: employeeId,
            name: familyMember.name,
            date_of_birth: familyMember.date_of_birth,
            family_member_type: familyMember.family_member_type,
          });

        if (insertError) {
          throw new Error(`Error inserting family member ${familyMember.name}: ${insertError.message}`);
        }
      }
    });
    await Promise.all(promises);
    toast.showToast({
      title: 'Success',
      description: 'Family details updated successfully.',
      status: 'success',
    });
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: error.message || 'Unable to update family details.',
      status: 'error',
    });
    console.error('Error updating family details:', error);
  }
};

export const fetchFamilyDetailsByEmployeeId = (employeeId) => async (dispatch) => {
  try {
    dispatch(setIsFamilyDetailsLoading(true));

    const { data, error } = await supabase
      .from('family_members')
      .select('name, date_of_birth, family_member_type')
      .eq('employee_id', employeeId);

    if (error) {
      toast.showToast({
        title: 'Error fetching family details',
        description: error.message,
        status: 'error',
      });
    } else {
      const familyDetails = {
        spouse_name: '',
        spouse_dob: null,
        children: [],
      };

      data.forEach((item) => {
        if (item.family_member_type === 'spouse') {
          familyDetails.spouse_name = item.name;
          familyDetails.spouse_dob = item.date_of_birth;
        } else if (item.family_member_type === 'child') {
          familyDetails.children.push({
            name: item.name,
            dob: item.date_of_birth,
          });
        }
      });

      dispatch(setFamilyDetails(familyDetails));
    }

    dispatch(setIsFamilyDetailsLoading(false));
  } catch (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to fetch family details.',
      status: 'error',
    });
    dispatch(setIsFamilyDetailsLoading(false));
  }
};

export const fetchEmployeeById = (employeeId) => async (dispatch) => {
    try {
      dispatch(setIsFamilyDetailsLoading(true));
      const { data, error } = await supabase
        .from('employee')
        .select('maritial_status')
        .eq('employee_id', employeeId)
        .single(); 
  
      if (error) {
        throw new Error(`Error fetching employee details: ${error.message}`);
      }
  
      return data;
    } catch (error) {
      customToast().showToast({
        title: 'Error',
        description: 'Unable to fetch employee details.',
        status: 'error',
      });
      dispatch(setIsFamilyDetailsLoading(false));
      return null;
    }
  };


