import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  assetDetails: {
    asset_type: '',
    serial_number: '',
  },
  isAssetDetailsLoading: false,
};

const manageAssetsReducers = createSlice({
  name: 'manageAssets',
  initialState,
  reducers: {
    setAssetDetails: (state, action) => {
      state.assetDetails = action.payload;
    },
    setIsAssetDetailsLoading: (state, action) => {
      state.isAssetDetailsLoading = action.payload;
    },
    updateAssetDetail: (state, action) => {
      const { key, value } = action.payload;
      state.assetDetails[key] = value;
    },
  },
});

export const {
  setAssetDetails,
  setIsAssetDetailsLoading,
  updateAssetDetail,

} = manageAssetsReducers.actions;

export default manageAssetsReducers.reducer;
