import React, { useEffect, useState } from "react";
import { Card } from "@chakra-ui/react";
import SearchInput from "components/search/Search";
import { MdAdd, MdDelete, MdEdit, MdOpenInNew, MdReceipt } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { closeDeleteConfirmation, openDeleteConfirmation, openEditForm, setSearch, setPage, closeForm, setEmployeesList, openAddForm, setSort, setLeaveTypes } from "./reducers/manageLeavesReducers";
import CustomAlertDialog from "components/alertDialog/AlertDialog";
import { deleteLeaveRequest, getAllLeaveRequests, fetchAllLeaves, fetchAllEmployees } from "./api/manageLeaves";
import { PAGINATION_DEFAULT_PAGE_SIZE } from "variables/common";
import Modal from "components/modal/Modal";
import { applyTagStyles } from "variables/regexVars";
import LeaveRequestEditForm from "./components/LeaveRequestEditForm";
import { downloadAttachmentsFromStorage, getAllLeaveTypes } from "../myLeaves/api/myLeaves";
import { formatDisplayDate } from "utils/dateUtils";
import { exportToExcel } from "utils/exportUtils";
import { TbFileExport } from "react-icons/tb";
import CustomTable from "views/admin/tables/components/CustomTable";
import { LEAVE_ATTACHMENTS_STORAGE } from "variables/common";

const ManageLeaves = () => {
  const {
    isFormOpen, leaveRequests, selectedLeave, isDeleteConfirmationOpen, search, page, pagination,
    isLoading, mode, sortBy, leaveTypes
  } = useSelector((state) => state.manageLeaves);
  const { employee } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [details, setDetails] = useState(null);
  const isMedicalLeave = (id) => {
    return leaveTypes.filter(leave => leave.leave_type_id === Number(id))[0]?.leave_type_name === "medical"
  }

  useEffect(() => {
    if (employee && employee?.employee_id) dispatch(getAllLeaveRequests(employee, search, page, sortBy));
    dispatch(closeForm());
  }, [dispatch, employee, search, page, sortBy]);

  useEffect(() => {
    getAllLeaveTypes().then((typesData) => { dispatch(setLeaveTypes(typesData)) });
  }, [dispatch]);

  useEffect(() => {
    fetchAllEmployees().then(({ data }) => { if (data) dispatch(setEmployeesList(data)) })
  }, [dispatch])

  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage + 1));
  };

  const handleExportToExcel = () => {
    // Extract the data to export
    if (employee) fetchAllLeaves(employee).then(({ leaveRequests }) => {
      const dataToExport = leaveRequests ? leaveRequests.map(row => ({
        "Employee Name": row.employee_name,
        "Start Date": row.start_date ? formatDisplayDate(row.start_date) : row.start_date,
        "End Date": row.end_date ? formatDisplayDate(row.end_date) : row.end_date,
        "Duration": row.is_half_day ? 0.5 : row.leave_duration,
        "Leave Type": row.leave_type_label,
        "Reason": row.leave_reason || "",
      })) : [];
      exportToExcel(dataToExport ?? [], 'leaves_requests');
    })
  };
  const handleSortChange = (newSortBy) => {
    dispatch(setSort(newSortBy));
  };
  const pageSize = PAGINATION_DEFAULT_PAGE_SIZE;
  const data = React.useMemo(() => leaveRequests, [leaveRequests]);
  const columns = React.useMemo(() => [
    {
      Header: 'Employee Name',
      accessor: 'employee_name',
    },
    {
      Header: 'Start Date',
      accessor: 'start_date',
      Cell: ({ value }) => (value ? formatDisplayDate(value) : value),
    },
    {
      Header: 'End Date',
      accessor: 'end_date',
      Cell: ({ value }) => (value ? formatDisplayDate(value) : value),
    },
    {
      Header: 'Duration',
      accessor: 'leave_duration',
    },
    {
      Header: 'Leave Type',
      accessor: 'leave_type_label',
    },
    {
      Header: 'Last Updated',
      accessor: 'updated_at',
      Cell: ({ value }) => (value ? formatDisplayDate(value) : value),
    },
    {
      Header: 'Reason',
      disableSortBy: true,
      // accessor: 'leave_reason',
      Cell: ({ row }) => (
        <button
          onClick={() => setDetails(row.original)}
          disabled={(!row.original.leave_reason || row.original.leave_reason === "") && row.original.files?.length === 0}
          className="text-xl text-blue-500 hover:text-blue-600 active:text-blue-700 dark:text-white dark:disabled:text-gray-500 disabled:text-gray-500 disabled:cursor-not-allowed"
        >
          <MdReceipt size={25} />
        </button>
      )
    },
    ...(employee && (employee.employee_role_name === "hr-manager" || employee.employee_role_name === "top-management") ? [
      {
        Header: 'Edit',
        disableSortBy: true,
        Cell: ({ row }) => (
          <button
            onClick={() => dispatch(openEditForm(row.original))}
            className="text-xl text-brand-500 hover:text-brand-600 active:text-brand-700 dark:text-white"
          >
            <MdEdit size={25} />
          </button>
        ),
      },
      {
        Header: 'Delete',
        disableSortBy: true,
        Cell: ({ row }) => (
          <button
            onClick={() => dispatch(openDeleteConfirmation(row.original))}
            className="text-xl text-red-500 hover:text-red-600 active:text-red-700 dark:text-white"
          >
            <MdDelete size={25} />
          </button>
        ),
      },
    ] : []),
  ], [dispatch, employee]);

  return (
    <>
      {isFormOpen && <LeaveRequestEditForm
        data={selectedLeave}
        mode={mode}
      />}
      <div className="my-6 ml-4">
        <Card className="bg-white dark:!bg-navy-800 px-4 pb-2 dark:shadow-none dark:text-lightPrimary ">
          <div className="flex mb-3 mx-[1rem] h-full items-center justify-between">
            <SearchInput
              value={search}
              onChange={(val) => dispatch(setSearch(val))}
              placeholder="Search.."
            />
            <div className="flex items-center justify-end">
              {!isFormOpen && employee && (employee.employee_role_name === "hr-manager" || employee.employee_role_name === "top-management") && <button
                onClick={() => dispatch(openAddForm())}
                title='Add new'
                className="text-xl text-brand-500 hover:text-brand-600 active:text-brand-700 dark:text-brand-700"
              >
                <MdAdd size={35} />
              </button>}
              <button
                onClick={handleExportToExcel}
                title="Export to excel"
                className="ml-2 text-xl text-brand-500 hover:text-brand-600 active:text-brand-700 dark:text-brand-700"
              >
                <TbFileExport size={28} />
              </button>
            </div>
          </div>
          <CustomTable
            columns={columns}
            data={data}
            isLoading={isLoading}
            pageIndex={page - 1}
            pageSize={pageSize}
            pageCount={Math.ceil(pagination.total / pageSize)}
            gotoPage={handlePageChange}
            canPreviousPage={page > 1}
            canNextPage={page * pageSize < pagination.total}
            totalItems={pagination.total}
            sortBy={sortBy}
            setSortBy={handleSortChange}
          />
        </Card>
      </div>
      <Modal title="Leave details" isOpen={details !== null} onClose={() => setDetails(null)}>
        <p style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: applyTagStyles(details?.leave_reason ?? "") }} />
        {details && details?.files?.length > 0 && (
          <>
            <h3 className="my-2 text-sm font-medium text-gray-600">Attachments</h3>
            {details?.files.map((file, index) => {
              return (
                <p className="mt-2 text-sm font-medium px-2 py-1 rounded-md bg-gray-600 flex gap-4 items-center justify-between">
                  <span className="cursor-default">{file?.startsWith(`${LEAVE_ATTACHMENTS_STORAGE}/`) ? file.split('/').slice(-1) : file}</span>
                  <MdOpenInNew className="cursor-pointer" onClick={() => downloadAttachmentsFromStorage(file)} fontSize={18} />
                </p>
              )
            })}
          </>
        )}
      </Modal>
      <CustomAlertDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={() => dispatch(closeDeleteConfirmation())}
        onConfirm={() => {
          if (selectedLeave && selectedLeave?.leave_request_id) {
            dispatch(
              deleteLeaveRequest(
                selectedLeave.leave_request_id,
                employee, search, page,
                isMedicalLeave(selectedLeave?.leave_type_id) ? selectedLeave?.files : [],
                sortBy
              )
            )
          }
        }}
        header="Confirm Delete"
        body="Are you sure you want to delete this leave request?"
        confirmText="Delete"
      />
    </>
  )
}

export default ManageLeaves;
