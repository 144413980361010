import { closeDeleteConfirmation, closeForm, setPaginationData, setLeaveRequests, setIsLoading } from '../reducers/manageLeavesReducers';
import customToast from 'utils/toastUtils';
import { supabase } from 'utils/supabase';
import { deleteAttachmentsFromStorage } from 'views/user/myLeaves/api/myLeaves';
import { uploadAttachment } from 'views/user/myLeaves/api/myLeaves';
import { PAGINATION_DEFAULT_PAGE_SIZE } from 'variables/common';
import { checkDuplicateLeave } from 'views/user/myLeaves/api/myLeaves';
import { getEmployeeReportingLine } from 'views/user/myLeaves/api/myLeaves';
import { LEAVE_ATTACHMENTS_STORAGE } from 'variables/common';

const toast = customToast();
const pageSize = PAGINATION_DEFAULT_PAGE_SIZE

const getAllLeaveRequestsData = async (search, page, sortBy) => {
  let { data, error } = search && search?.length > 0 ? await supabase
    .from('leave_requests_view')
    .select('*').order(sortBy[0].id, { ascending: !sortBy[0].desc })
    .or(`employee_name.ilike.%${search}%, leave_type_label.ilike.%${search}%${Number(search) ? `,leave_duration.eq.${search}` : ""}`)
    .range((page - 1) * pageSize, (pageSize * page) - 1) :
    await supabase
      .from('leave_requests_view')
      .select('*').range((page - 1) * pageSize, (pageSize * page) - 1)
      .order(sortBy[0].id, { ascending: !sortBy[0].desc })
  const { count, err } = search && search?.length > 0 ? await supabase
    .from('leave_requests_view')
    .select('*', { count: 'exact', head: true })
    .or(`employee_name.ilike.%${search}%, leave_type_label.ilike.%${search}%${Number(search) ? `,leave_duration.eq.${search}` : ""}`) :
    await supabase
      .from('leave_requests_view')
      .select('*', { count: 'exact', head: true })
  if (err) toast.showToast({
    title: 'Count error.',
    description: err?.message && typeof err?.message === 'string' ? err?.message : 'Could not get total count of records.',
    status: 'error',
  })
  if (error) toast.showToast({
    title: 'An error occurred.',
    description: error?.message && typeof error?.message === 'string' ? error?.message : 'Leave data could not be fetched.',
    status: 'error',
  })
  return { data: data ?? [], count: count ?? data?.length }
}

const getLeaveRequestsDataForRepManager = async (employee_id, search, page, sortBy) => {
  let emplIds = [employee_id]
  const { data: managedEmpl } = await supabase.from('employee_manager')
    .select("employee_id").eq('manager_id', employee_id)
  if (managedEmpl && managedEmpl?.length > 0) {
    emplIds.push(managedEmpl.map(emp => emp.employee_id))
  }
  let { data, error } = search && search?.length > 0 ? await supabase
    .from('leave_requests_view')
    .select('*').in('employee_id', emplIds)
    .or(`employee_name.ilike.%${search}%, leave_type_label.ilike.%${search}%${Number(search) ? `,leave_duration.eq.${search}` : ""}`)
    .order(sortBy[0].id, { ascending: !sortBy[0].desc })
    .range((page - 1) * pageSize, (pageSize * page) - 1) : await supabase
      .from('leave_requests_view')
      .select('*').in('employee_id', emplIds)
      .order(sortBy[0].id, { ascending: !sortBy[0].desc })
      .range((page - 1) * pageSize, (pageSize * page) - 1)
  const { count, err } = search && search?.length > 0 ? await supabase
    .from('leave_requests_view')
    .select('*', { count: 'exact', head: true })
    .in('employee_id', emplIds)
    .or(`employee_name.ilike.%${search}%, leave_type_label.ilike.%${search}%${Number(search) ? `,leave_duration.eq.${search}` : ""}`) :
    await supabase
      .from('leave_requests_view')
      .select('*', { count: 'exact', head: true })
      .in('employee_id', emplIds)
  if (err) toast.showToast({
    title: 'Count error.',
    description: err?.message && typeof err?.message === 'string' ? err?.message : 'Could not get total count of records.',
    status: 'error',
  })
  if (error) toast.showToast({
    title: 'An error occurred.',
    description: error?.message && typeof error?.message === 'string' ? error?.message : 'Leave data could not be fetched.',
    status: 'error',
  })
  return { data: data ?? [], count: count ?? data?.length }
}

export const getAllLeaveRequests = (employee, search, page, sortBy) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true))
    const { data, count } = employee && (employee?.employee_role_name === "hr-manager" || employee.employee_role_name === "top-management") ?
      await getAllLeaveRequestsData(search, page, sortBy) :
      await getLeaveRequestsDataForRepManager(employee.employee_id, search, page, sortBy)
    if (data) {
      dispatch(setLeaveRequests(data ?? []));
      dispatch(setPaginationData({
        total: count ?? data.length,
      }))
    }
    dispatch(setIsLoading(false))
  } catch (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Data could not be fetched.',
      status: 'error',
    })
  }
};

export const addNewLeaveRequest = (leaveData, employee, files, userId, search, page, sortBy) => async (dispatch) => {
  let isAdded = false;
  delete leaveData.files;
  leaveData.start_date = leaveData.start_date.toLocaleDateString("en-US");
  leaveData.end_date = leaveData.end_date ? leaveData.end_date.toLocaleDateString("en-US") : leaveData.start_date;
  const isDuplicate = await checkDuplicateLeave(leaveData, leaveData.employee_id);
  if (isDuplicate) {
    // Handle the case where a duplicate leave request is found
    toast.showToast({
      title: 'Could not add leave.',
      description: "Duplicate data. This employee have already ssentered a leave for the date(s)",
      status: 'error',
    })
    dispatch(closeForm());
    return;
  }
  const { data, error } = await supabase
    .from('leave_request')
    .insert([leaveData])
    .select();
  if (error) {
    let errorMsg = error?.message && typeof error?.message === 'string' ? error?.message : 'Unable to add leave request.'
    toast.showToast({
      title: 'An error occurred.',
      description: errorMsg,
      status: 'error',
    })
    dispatch(closeForm());
  }
  else if (data && data[0]?.leave_request_id) {
    const uploaded = [];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i += 1) {
        const resp = await uploadAttachment(files[i], userId)
        if (resp && resp !== '') uploaded.push(resp)
      }
    }
    if (uploaded && uploaded.length > 0) {
      const { error: err } = await supabase.from('leave_request_attachments').insert(
        uploaded.map(file => ({ attachment_path: file, leave_request_id: data[0].leave_request_id }))
      ).select()
      if (err) toast.showToast({
        title: 'An error occurred.',
        description: err?.message && typeof err?.message === 'string' ? err?.message : 'Unable to add attachments.',
        status: 'error',
      })
    }
    dispatch(getAllLeaveRequests(employee, search, page, sortBy));
    dispatch(closeForm());
    isAdded = true
    toast.showToast({
      title: 'Added.',
      description: 'Leave added successfully',
      status: 'success',
    })
  }
  return isAdded
};


export const editLeaveRequest = (leaveData, files, prevFiles, userId, search, page, employee, sortBy) => async (dispatch) => {
  leaveData.start_date = leaveData.start_date.toLocaleDateString("en-US");
  if (leaveData.is_half_day) {
    leaveData.end_date = leaveData.start_date
  } else {
    leaveData.end_date = leaveData.end_date ? leaveData.end_date.toLocaleDateString("en-US") : leaveData.start_date;
  }
  const isDuplicate = await checkDuplicateLeave(leaveData, leaveData.employee_id, leaveData.leave_request_id);
  if (isDuplicate) {
    // Handle the case where a duplicate leave request is found
    toast.showToast({
      title: 'Could not add leave.',
      description: "Duplicate data. This employee have already entered a leave for these date(s)",
      status: 'error',
    })
    dispatch(closeForm());
    return;
  }
  const removedAttachments = []; // Track removed attachments

  // Check if files have changed
  const existingFileNames = prevFiles || [];
  const newFileNames = files.map((file) => file.name);

  // Find removed attachments
  existingFileNames.forEach((fileName) => {
    if (!newFileNames.includes(fileName)) {
      removedAttachments.push(fileName);
    }
  });

  delete leaveData.files;
  // Update leave request data
  const { data, error } = await supabase
    .from('leave_request')
    .update({ ...leaveData, updated_at: new Date().toLocaleString("en-US") })
    .eq('leave_request_id', leaveData.leave_request_id)
    .select();

  if (error) {
    let errorMsg = error?.message && typeof error?.message === 'string' ? error?.message : 'Unable to update leave request.'
    if (error?.message && typeof error?.message === 'string' && error?.message?.includes("unique_employee_leave")) {
      errorMsg = "Duplicate data. You have already entered a leave for this date"
    }
    toast.showToast({
      title: 'An error occurred.',
      description: errorMsg,
      status: 'error',
    })
    dispatch(closeForm());
  } else if (data && data[0]?.leave_request_id) {
    // Upload new files
    const uploaded = [];
    const newFiles = files.filter(file => !file.name.startsWith(`${LEAVE_ATTACHMENTS_STORAGE}/`))
    if (newFiles.length > 0) {
      for (let i = 0; i < newFiles.length; i += 1) {
        const resp = await uploadAttachment(newFiles[i], userId);
        if (resp && resp !== '') uploaded.push(resp);
      }
    }

    // Delete removed attachments
    if (removedAttachments.length > 0) {
      await deleteAttachmentsFromStorage(removedAttachments)
      await supabase
        .from('leave_request_attachments')
        .delete()
        .in('attachment_path', removedAttachments);
    }

    // Insert new attachments
    if (uploaded.length > 0) {
      const { error: err } = await supabase
        .from('leave_request_attachments')
        .insert(
          uploaded.map((file) => ({
            attachment_path: file,
            leave_request_id: leaveData.leave_request_id,
          }))
        )
        .select();

      if (err) {
        toast.showToast({
          title: 'An error occurred.',
          description: err?.message && typeof err?.message === 'string' ? err?.message : 'Unable to add attachments.',
          status: 'error',
        });
        dispatch(closeForm());
      }
    }

    dispatch(getAllLeaveRequests(employee, search, page, sortBy));
    dispatch(closeForm());
    toast.showToast({
      title: 'Updated.',
      description: 'Leave updated successfully',
      status: 'success',
    });
  }
};

export const deleteLeaveRequest = (leaveId, employee, search, page, files, sortBy) => async (dispatch) => {
  const { error } = await supabase
    .from('leave_request')
    .update({ 'leave_status': 'deleted' })
    .eq('leave_request_id', leaveId)
  if (error) {
    toast.showToast({
      title: 'Error',
      description: error?.message && typeof error?.message === 'string' ? error?.message : 'Unable to delete leave.',
      status: 'error',
    })
    dispatch(closeDeleteConfirmation())
  } else {
    if (files && files?.length > 0) await deleteAttachmentsFromStorage(files)
    dispatch(getAllLeaveRequests(employee, search, page, sortBy));
    dispatch(closeDeleteConfirmation());
    toast.showToast({
      title: 'Deleted.',
      description: 'Leave deleted successfully',
      status: 'success',
    })
  }
};

export const fetchAllLeaveRequests = async () => {
  const { data, error } = await supabase
    .from('leave_requests_view')
    .select('*')
    .neq('leave_status', 'deleted');

  if (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: error?.message && typeof error?.message === 'string' ? error?.message : 'Leave data could not be fetched.',
      status: 'error',
    });
    return { data: [], error };
  }

  return { data: data ?? [], error: null };
}

export const fetchAllLeaveRequestsForRepManager = async (employee_id) => {
  let emplIds = [employee_id]
  const { data: managedEmpl } = await supabase.from('employee_manager')
    .select("employee_id").eq('manager_id', employee_id)
  if (managedEmpl && managedEmpl?.length > 0) {
    emplIds.push(managedEmpl.map(emp => emp.employee_id))
  }
  const { data, error } = await supabase
    .from('leave_requests_view')
    .select('*').in('employee_id', emplIds)

  if (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: error?.message && typeof error?.message === 'string' ? error?.message : 'Leave data could not be fetched for export.',
      status: 'error',
    });
    return { data: [], error };
  }

  return { data: data ?? [], error: null };
}

export const fetchAllLeaves = async (employee) => {
  try {
    const { data } = employee && (employee?.employee_role_name === "hr-manager" || employee.employee_role_name === "top-management") ?
      await fetchAllLeaveRequests() :
      await fetchAllLeaveRequestsForRepManager(employee.employee_id);
    if (data && data.length > 0) {
      return { leaveRequests: data }
    }
    return { leaveRequests: [], error: null };
  } catch (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Data could not be fetched for export.',
      status: 'error',
    });
    return { leaveRequests: [], error };
  }
};

export const fetchAllEmployees = async () => {
  try {
    const { data, error } = await supabase
      .from('employee')
      .select('*')
      .eq('employee_status', 'TRUE')
      .order('employee_name', { ascending: true })
    if (data) return { data };
    if (error) toast.showToast({
      title: 'An error occurred.',
      description: error?.message && typeof error?.message === 'string' ? error?.message : 'Data could not be fetched.',
      status: 'error',
    });
  } catch (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Data could not be fetched.',
      status: 'error',
    });
    return { data: [] };
  }
};

export const getEmployeeAndReportingLine = async (employee_id) => {
  const { data } = await supabase
    .from('employees_view')
    .select('*').eq('employee_id', employee_id);
  if (data && data?.length === 1) {
    const reportingLine = await getEmployeeReportingLine(data[0])
    if (reportingLine) {
      return { employeeInfo: data[0], reportingLine: reportingLine.map(emp => emp.employee_id) }
    }
  }
  return {employeeInfo: null, reportingLine: []}
}