import React from "react";

// Icon Imports
import {
  MdBarChart,
  MdCalendarMonth,
  MdDonutLarge,
  MdManageAccounts,
  MdManageHistory,
  MdPerson,
  MdNewspaper,
  MdCalendarToday
} from "react-icons/md";
import { FaUserAlt, FaBuilding, FaFileSignature, FaUserTag } from "react-icons/fa";
import { RiOrganizationChart } from "react-icons/ri";
import ManageLeaves from "views/user/manageLeaves";
import AddHolidays from "views/admin/addHolidays";
import Employees from "views/admin/manageEmployees";
import LeavesQuota from "views/user/leavesQuota";
import MyLeaves from "views/user/myLeaves";
import CalendarView from "views/user/calendarView";
import { FaRegCalendarMinus } from "react-icons/fa";

// for admin (hr-manager) pages, set layout : "/hr", 
// for (hr-manager or project-manager) pages, set layout : "/manage", 
// for shared pages of all users, set layout: "/"

const routes = [
  {
    name: "My Leaves",
    layout: "/",
    path: "my-leaves",
    icon: <MdBarChart className="h-6 w-6" />,
    component: <MyLeaves />,
  },
  {
    name: "Leaves Summary",
    layout: "/",
    path: "leaves-summary",
    icon: <MdDonutLarge className="h-6 w-6" />,
    component: <LeavesQuota />,
  },
  {
    name: "Calendar View",
    layout: "/",
    path: "calendar-view",
    icon: <MdCalendarMonth className="h-6 w-6" />,
    component: <CalendarView />,
  },
  {
    name: "Manage Leaves",
    layout: "/manage",
    path: "leave-requests",
    icon: <MdManageHistory className="h-6 w-6" />,
    component: <ManageLeaves />,
  },
  {
    name: "Add Holidays",
    layout: "/hr",
    path: "public-holidays",
    icon: <FaRegCalendarMinus className="h-5 w-5" />,
    component: <AddHolidays />,
  },
  {
    name: "Manage Employees",
    layout: "/hr",
    path: "manage-employees",
    icon: <MdManageAccounts className="h-6 w-6" />,
    component: <Employees />,
  },
  {
    name: "My Info",
    layout: "/hr1",
    path: "personal-information",
    icon: <MdPerson className="h-6 w-6" />,
    // component: <PersonalInformation />,
  },
  {
    name: "News & Updates",
    layout: "/hr1",
    path: "News",
    icon: <MdNewspaper className="h-6 w-6" />,
    // component: <PersonalInformation />,
  },
  {
    name: "Calendar",
    layout: "/hr1",
    path: "Calendar",
    icon: <MdCalendarToday className="h-6 w-6" />,
    // component: <PersonalInformation />,
  },
  {
    name: "Self Assessments",
    layout: "/hr1",
    path: "self-assessments",
    icon: <FaUserTag className="h-6 w-6" />,
    // component: <SelfAssessments />,
  },
  {
    name: "Performance Rating",
    layout: "/hr1",
    path: "performance-rating",
    icon: <FaUserAlt className="h-6 w-6" />,
    // component: <PerformanceRating />,
  },
  {
    name: "Salary Slips",
    layout: "/hr1",
    path: "salary-slips",
    icon: <FaFileSignature className="h-6 w-6" />,
    // component: <SalarySlips />,
  },
  {
    name: "Salary History",
    layout: "/hr1",
    path: "salary-history",
    icon: <FaBuilding className="h-6 w-6" />,
    // component: <SalaryHistory />,
  },
  {
    name: "Departments",
    layout: "/hr1",
    path: "departments",
    icon: <MdManageAccounts className="h-6 w-6" />,
    // component: <Departments />,
  },
  {
    name: "Org Chart",
    layout: "/hr1",
    path: "org-chart",
    icon: <RiOrganizationChart className="h-6 w-6" />,
    // component: <OrgChart />,
  },
  {
    name: "Directory",
    layout: "/hr1",
    path: "directory",
    icon: <FaUserAlt className="h-6 w-6" />,
    // component: <Directory />,
  },
];

export default routes;
