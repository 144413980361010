import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  familyDetails: {
    spouse_name: '',
    spouse_dob: null,
    children: [], 
  },
  isFamilyDetailsLoading: false,
};

const manageFamilyReducers = createSlice({
  name: 'manageFamily',
  initialState,
  reducers: {
    setFamilyDetails: (state, action) => {
      state.familyDetails = action.payload;
    },
    updateFamilyDetail: (state, action) => {
      const { key, value } = action.payload;
      state.familyDetails[key] = value;
    },
    updateChildDetail: (state, action) => {
      const { index, field, value } = action.payload;
      state.familyDetails.children[index][field] = value;
    },
    setIsFamilyDetailsLoading: (state, action) => {
      state.isFamilyDetailsLoading = action.payload;
    },
    clearFamilyDetails: (state) => {
      state.familyDetails = null;
    },
  },
});

export const {
  setFamilyDetails,
  updateFamilyDetail,
  updateChildDetail,
  setIsFamilyDetailsLoading,
  clearFamilyDetails
} = manageFamilyReducers.actions;

export default manageFamilyReducers.reducer;
