import { closeDeleteConfirmation, setPaginationData, setEmployees, setEmployeeRoles, setManagers, setIsLoading, setDepartments, setNewEmployeeData } from '../reducers/manageEmployeesReducers';
import customToast from 'utils/toastUtils';
import { supabase } from 'utils/supabase';
import { setLoggedInEmployee } from 'layouts/reducers/authReducers';
import { PAGINATION_DEFAULT_PAGE_SIZE } from 'variables/common';

const toast = customToast();
const pageSize = PAGINATION_DEFAULT_PAGE_SIZE

export const getAllEmployeeRoles = () => async (dispatch) => {
  const { data, error } = await supabase
    .from('employee_roles').select('*')
  if (data) dispatch(setEmployeeRoles(data));
  if (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Data could not be fetched.',
      status: 'error',
    })
  }
};

export const getAllDepartments = () => async (dispatch) => {
  const { data, error } = await supabase
    .from('departments').select('*')
  if (data) dispatch(setDepartments(data));
  if (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Data could not be fetched.',
      status: 'error',
    })
  }
};

export const getEmployeeManagers = () => async (dispatch) => {
  let { data: managers, error } = await supabase
    .from('employees_view')
    .select('employee_id, employee_email, employee_name').eq('employee_role_name', 'reporting-manager')
  if (managers) dispatch(setManagers(managers))
  if (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Data could not be fetched.',
      status: 'error',
    })
  }
};

export const fetchEmployeeByEmail = (email) => async (dispatch) => {
  let employee = null;
  try {
    let { data, error } = await supabase
      .from('employees_view').select('*').eq('employee_email', email)
    if (error) toast.showToast({
      title: 'An error occurred.',
      description: error?.message && typeof error?.message === 'string' ? error?.message : 'Employee data could not be fetched.',
      status: 'error',
    })
    else if (data && data?.length > 0) {
      employee = data[0]
      dispatch(setLoggedInEmployee(data[0]));
    }
  } catch (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Employee data could not be fetched.',
      status: 'error',
    })
  }
  return employee
};

export const getAllEmployees = (search, page, sortBy) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const { data, error } = search && search?.length > 0 ? await supabase
      .from('employees_view').select('*')
      .or(`employee_name.ilike.%${search}%, employee_email.ilike.%${search}%, manager_name.ilike.%${search}%, department_label.ilike.${search}%`)
      .order(sortBy[0].id, { ascending: !sortBy[0].desc })
      .range((page - 1) * pageSize, (pageSize * page) - 1) : await supabase
        .from('employees_view')
        .select('*').order(sortBy[0].id, { ascending: !sortBy[0].desc })
        .range((page - 1) * pageSize, (pageSize * page) - 1)
    const { count, err } = search && search?.length > 0 ? await supabase
      .from('employees_view')
      .select('*', { count: 'exact', head: true })
      .or(`employee_name.ilike.%${search}%, employee_email.ilike.%${search}%, manager_name.ilike.%${search}%, department_label.ilike.${search}%`) :
      await supabase
        .from('employees_view')
        .select('*', { count: 'exact', head: true })
    if (err) toast.showToast({
      title: 'Count error.',
      description: err?.message && typeof err?.message === 'string' ? err?.message : 'Could not get total count of records.',
      status: 'error',
    })
    if (error) toast.showToast({
      title: 'An error occurred.',
      description: error?.message && typeof error?.message === 'string' ? error?.message : 'Employees data could not be fetched.',
      status: 'error',
    })
    else if (data) {
      dispatch(setEmployees(data ?? []));
      dispatch(setPaginationData({
        total: count ?? data?.length
      }))
    }
    dispatch(setIsLoading(false));
  } catch (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Data could not be fetched.',
      status: 'error',
    })
    dispatch(setIsLoading(false));
  }
};

export const addNewEmployee = (employeeData, managerId, search, page, sortBy) => async (dispatch) => {
  try {
    employeeData.date_of_joining = employeeData.date_of_joining.toLocaleDateString("en-US");
    const { data, error } = await supabase
      .from('employee')
      .insert([employeeData])
      .select()
    if (error) toast.showToast({
      title: 'An error occurred.',
      description: error?.message && typeof error?.message === 'string' ? error?.message : 'Unable to add employee.',
      status: 'error',
    })
    else if (data) {
      const empManagerData = {
        employee_id: data[0].employee_id,
        manager_id: managerId
      }
      const { error: err } = await supabase
        .from('employee_manager')
        .insert([empManagerData])
        .select()
      if (err) toast.showToast({
        title: 'An error occurred.',
        description: err?.message && typeof err?.message === 'string' ? err?.message : 'Unable to add manager.',
        status: 'error',
      })

      dispatch(setNewEmployeeData(data[0]));
      dispatch(getAllEmployees(search, page, sortBy));
      toast.showToast({
        title: 'Added.',
        description: 'Employee added successfully',
        status: 'success',
      })
    }
  } catch (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Unable to add employee.',
      status: 'error',
    })
  }
};

export const editEmployee = (employee_id, manager_id, employeeData, search, page, sortBy) => async (dispatch) => {
  delete employeeData.manager_id;
  employeeData.date_of_joining = employeeData.date_of_joining.toLocaleDateString("en-US");
  const { data, error } = await supabase
    .from('employee')
    .update(employeeData)
    .eq('employee_id', employee_id)
    .select()
  if (data) {
    if (manager_id) {
      const { error: err } = await supabase
        .from('employee_manager')
        .update({ manager_id: manager_id })
        .eq('employee_id', employee_id)
        .select()

      if (err) toast.showToast({
        title: 'An error occurred.',
        description: err?.message && typeof err?.message === 'string' ? err?.message : 'Unable to add manager.',
        status: 'error',
      })
    }
    dispatch(getAllEmployees(search, page, sortBy));
    toast.showToast({
      title: 'Updated.',
      description: 'Employee updated successfully',
      status: 'success',
    })
  }
  if (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: error?.message && typeof error?.message === 'string' ? error?.message : 'Unable to update employee.',
      status: 'error',
    })
  }
};

export const deleteEmployee = (employeeId, search, page, sortBy) => async (dispatch) => {
  const { error } = await supabase
    .from('employee')
    .delete()
    .eq('employee_id', employeeId)
  if (error) {
    toast.showToast({
      title: 'Error',
      description: error?.message && typeof error?.message === 'string' ? error?.message : 'Unable to delete employee.',
      status: 'error',
    })
    dispatch(closeDeleteConfirmation())
  } else {
    dispatch(getAllEmployees(search, page, sortBy));
    dispatch(closeDeleteConfirmation());
    toast.showToast({
      title: 'Deleted.',
      description: 'Employee deleted successfully',
      status: 'success',
    })
  }
};