import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import layoutReducers from "layouts/reducers/layoutReducers";
import authReducers from "layouts/reducers/authReducers";
import manageEmployeesReducers from "views/admin/manageEmployees/reducers/manageEmployeesReducers";
import manageLeavesReducers from "views/user/manageLeaves/reducers/manageLeavesReducers";
import addHolidaysReducers from "views/admin/addHolidays/reducers/manageAddHolidayReducers";
import leavesQuotaReducers from "views/user/leavesQuota/reducers/leavesQuotaReducers";
import myLeavesReducers from "views/user/myLeaves/reducers/myLeavesReducers";
import leavesCalendarReducers from "views/user/calendarView/reducers/leavesCalendarReducers";
import manageAssetsReducers from "views/admin/manageEmployees/reducers/manageAssetsReducers";
import manageBankReducers from "views/admin/manageEmployees/reducers/manageBankReducers";
import manageContactReducers from "views/admin/manageEmployees/reducers/manageContactReducers";
import manageEmployeeHistoryReducers from "views/admin/manageEmployees/reducers/manageEmployeeHistoryReducers";
import manageFamilyReducers from "views/admin/manageEmployees/reducers/manageFamilyReducers";
import manageMedicalInfoReducers from "views/admin/manageEmployees/reducers/manageMedicalInfoReducers";

const middleware = applyMiddleware(thunk);
export const store = configureStore({
  reducer: {
    auth: authReducers,
    layout: layoutReducers,
    manageEmployees: manageEmployeesReducers,
    manageLeaves: manageLeavesReducers,
    addHoliday: addHolidaysReducers,
    leavesQuota: leavesQuotaReducers,
    myLeaves: myLeavesReducers,
    leavesCalendar: leavesCalendarReducers,
    manageAssets: manageAssetsReducers,
    manageBank: manageBankReducers,
    manageContacts: manageContactReducers,
    manageEmployeeHistory: manageEmployeeHistoryReducers,
    manageFamily: manageFamilyReducers,
    manageMedical: manageMedicalInfoReducers,

  },
}, middleware);


